import React, { useEffect } from 'react'
import { linkReplace } from '@loomhq/loom-embed'

const LoomVideo = ({ videoLink, className }) => {
  useEffect(() => {
    linkReplace('.loom-video')
  }, [videoLink])
  return (
    <div className={className}>
      <a className="loom-video" href={videoLink} />
    </div>
  )
}

export default LoomVideo
