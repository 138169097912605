import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import PodcastPlayer from 'components/PodcastPlayer'

const StyledPodcastPlayer = styled(PodcastPlayer)`
  margin: 2em 0;
`

/*
This component is used to render an inline podcast player. Can't use PodcastPlayer component
directly in the rich text renderer because the embedded entry does not return the same id as
the other queries for some reason... so need to get the id here by finding the correct ep number
from redux state.
*/
const InlinePodcastPlayer = ({ episodeNumber }) => {
  const podcastPlaylist = useSelector((state) => state.podcastPlaylist)

  if (!podcastPlaylist) {
    return null
  }

  const initialEpisodeId = podcastPlaylist.find(
    (x) => x.episodeNumber === episodeNumber
  )?.id

  return <StyledPodcastPlayer inline initialEpisodeId={initialEpisodeId} />
}

export default InlinePodcastPlayer
