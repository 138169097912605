import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import OutboundLink from 'components/OutboundLink'
import LoomVideo from 'components/LoomVideo'
import InlinePodcastPlayer from 'components/InlinePodcastPlayer'
import { sizes } from 'styles'

const StyledImage = styled.img`
  width: 100%;
  :not(:first-child) {
    margin-top: 28px;
  }
`

const StyledLogo = styled.img`
  max-height: 38px;
  vertical-align: middle;
  margin: 2px 8px 0 8px;
  max-width: 100px;
`

const StyledText = styled.div`
  white-space: pre-line;
  line-height: 26px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  :empty,
  > b:empty {
    line-height: 12px;
  }

  :empty:before,
  > b:empty:before {
    content: ' ';
    white-space: pre;
  }
`

const StyledH2 = styled.h2`
  font-size: 32px;
  line-height: 30px;
  margin-top: 40px;

  @media ${sizes.desktop} {
    margin-top: 50px;
  }
`

const StyledH3 = styled.h3`
  font-size: 24px;
  line-height: 30px;
  margin-top: 40px;

  @media ${sizes.desktop} {
    margin-top: 50px;
  }
`

const StyledH4 = styled.h4`
  font-size: 18px;
  margin-top: 30px;
`

const StyledLoomVideo = styled(LoomVideo)`
  margin: 40px 0;
`

export const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <StyledText>{children}</StyledText>,
    [BLOCKS.HEADING_1]: (node, children) => (
      <StyledH2 data-title={node.content[0]?.value}>{children}</StyledH2>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <StyledH3 data-title={node.content[0]?.value}>{children}</StyledH3>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <StyledH4 data-title={node.content[0]?.value}>{children}</StyledH4>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (node.data?.target?.fields) {
        return <StyledImage src={node.data.target.fields.file['en-US'].url} />
      }
      return null
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (node.data?.target?.sys?.contentType?.sys?.id === 'embedWidget') {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: node.data.target.fields.embedCode['en-US'],
            }}
          />
        )
      }
      return null
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      if (node.data?.target?.sys?.contentType?.sys?.id === 'podcastEpisode') {
        return (
          <InlinePodcastPlayer
            episodeNumber={node.data?.target?.fields?.episodeNumber['en-US']}
          />
        )
      }
      if (node.data?.target?.sys?.contentType?.sys?.id === 'loomVideo') {
        return (
          <StyledLoomVideo
            videoLink={node.data?.target?.fields?.loomLink['en-US']}
          />
        )
      }
      if (node.data?.target?.sys?.contentType?.sys?.id === 'logo') {
        return (
          <StyledLogo
            src={
              node.data.target.fields.image['en-US'].fields.file['en-US'].url
            }
          />
        )
      }
    },
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.startsWith('https://buildingremotely.com')) {
        return (
          <OutboundLink href={node.data.uri}>
            {node.content[0].value}
          </OutboundLink>
        )
      }
      return (
        <OutboundLink
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.content[0].value}
        </OutboundLink>
      )
    },
  },
}

// TODO add rich text image rendering with gatsby-image
export default ({ richText }) =>
  documentToReactComponents(richText, richTextOptions)
